/* react-phone-input-2 style overrides: */
.react-tel-input {
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.87);
  font-family: Rubik, Arial;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.1876em;
  margin-bottom: 30px;
}

.react-tel-input .form-control {
  font: inherit;
  letter-spacing: inherit;
  width: 100%;
}

.react-tel-input .form-control:focus {
  outline: 0;
  border-color: #03ade6;
  box-shadow: 0 0 0 1px #03ade6;
}

.react-tel-input {
  &:focus-within {
    .special-label {
      color: #03ade6;
    }
  }
}

.react-tel-input .special-label {
  color: rgba(0, 0, 0, 0.54);
  left: 9px;
  top: -9px;
  font-size: 12px;
}

.react-tel-input .invalid-number-message {
  font-size: 12px;
  left: 9px;
  top: -9px;
}

.react-tel-input .form-control + div:before {
  background: white;
  color: rgba(0, 0, 0, 0.54);
  /* content: "Contact Number"; */
  display: block;
  font-size: 12px;
  left: 11px;
  padding: 0 5px;
  position: absolute;
  top: -8px;
  white-space: nowrap;
}

.react-tel-input .form-control:focus + div:before {
  color: #03ade6;
}
